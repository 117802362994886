import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmailEditorForm = _resolveComponent("EmailEditorForm")!

  return (_ctx.showForm)
    ? (_openBlock(), _createBlock(_component_EmailEditorForm, {
        key: 0,
        onCreateTemplate: _ctx.handeCreateTemplate,
        onUpdateTemplate: _ctx.handleUpdateTemplate,
        pageLoading: _ctx.pageLoading,
        formSubmitted: _ctx.formSubmitted,
        title: _ctx.title,
        placeholderOptions: _ctx.placeholderOptions,
        template: _ctx.template
      }, null, 8, ["onCreateTemplate", "onUpdateTemplate", "pageLoading", "formSubmitted", "title", "placeholderOptions", "template"]))
    : _createCommentVNode("", true)
}